.ant-picker,
.ant-select-selector {
  background-color: var(--bs-gray-100) !important;
  border-color: var(--bs-gray-300) !important;
  color: var(--bs-gray-700) !important;
  transition: color 0.2s ease;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5;
  width: 100%;
  // height: auto !important;
  display: flex;
  align-items: center;
}

.ant-picker-input {
  > input {
    color: #7595ac !important;
    font-weight: bold;
  }
}

.modal-backdrop,
.modal {
  z-index: 1000 !important;
}

.fv-row {
  .ant-picker,
  .ant-select-selector {
    width: auto;
    padding: 0.775rem 1rem 0.775rem 1rem !important;
  }
}

.admin-info-modal {
  .modal-content {
    width: auto !important;
  }
  justify-content: center;
}

.w-full {
  width: 100%;
}

.ant-switch-checked {
  background-color: #009ef7 !important;
}

.ant-upload-wrapper,
.ant-upload-list,
.ant-upload,
.ant-upload-list-item-container,
.ant-upload-select {
  width: 100% !important;
  min-height: 200px;
}

@media (max-width: 480px) {
  .ant-picker-panel-layout {
    width: 320px;
    display: flex;
    flex-direction: column;
  }
  .ant-picker-presets {
    height: 120px;
    width: 100%;
    max-width: 100% !important;
    display: inline;
    > ul {
      width: 100%;
    }
  }
  .ant-picker-panels {
    width: 320px;
    display: flex;
    flex-direction: column;
  }
  .ant-picker-panel {
    width: 100%;
  }
  .ant-picker-content {
    width: 100% !important;
  }
  .ant-picker-date-panel {
    width: 100% !important;
  }
  .card-body {
    padding: 0 16px;
  }
}

#kt_page_title {
  width: 100%;
}

.ant-picker,
.ant-select-selector {
  background-color: var(--bs-gray-100) !important;
  border-color: var(--bs-gray-100) !important;
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5;
  width: 100%;
  padding: 0.775rem 1rem 0.775rem 1rem !important;
}

.modal-backdrop,
.modal {
  z-index: 1000 !important;
}

.fv-row {
  .ant-picker,
  .ant-select-selector {
    width: auto;
    padding: 0.775rem 1rem 0.775rem 1rem !important;
  }
}

.admin-info-modal {
  .modal-content {
    width: auto !important;
  }
}

#kt_page_title {
  width: 100%;
}

//phase 2

.ant-select-selector {
  width: auto;
  padding: 0.5rem 1rem 0.775rem 1rem !important;
  min-height: calc(1.5em + 1.65rem + 2px);
}

.ant-select-tree-checkbox.ant-select-tree-checkbox-disabled {
  display: none;
}

.header-datepicker {
  // margin-bottom: 30px;
  .ant-picker,
  .ant-select-selector {
    background-color: white !important;
    border-color: var(--bs-gray-100) !important;
    color: var(--bs-gray-700);
    transition: color 0.2s ease;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.5;
    width: 100%;
    padding: 0.775rem 1rem 0.775rem 1rem !important;
  }
}

.app-sidebar .app-sidebar-logo {
  border: none !important;
}

.ant-select-tree-title {
  color: #7e8299 !important;
  font-weight: 600 !important;
  font-size: 0.95rem !important;
}

.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: -1px !important;
}

.ant-select-selection-item {
  color: #7e8299 !important;
  font-weight: 500 !important;
  font-size: 0.95rem !important;
}

.ant-select {
  display: flex !important;
  align-items: center !important;
}

.ant-select-single {
  font-size: 0;
  height: 100%;
}

.mini-select {
  .ant-select-selector {
    background-color: white !important;
    border: white !important;
    //padding: 0 !important;
    // width: 120px !important;
  }

  .ant-select-selection-item {
    font-size: smaller !important;
  }

  .ant-select-selection-overflow {
    justify-content: center !important;
    gap: 24px;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    caret-color: transparent;
  }

  // .ant-select-show-search {
  //   visibility: hidden;
  // }

  .ant-select-open {
    .ant-select-arrow {
      display: none;
    }
  }
}

.filter-header {
  .ant-picker,
  .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    background-color: var(--bs-gray-100) !important;
    font-size: 0.95em !important;
    border: 1px solid var(--bs-gray-200) !important;
    padding: 0.55rem 0.75rem !important;
    width: auto !important;
  }

  .form-control {
    background-color: var(--bs-gray-100) !important;
    font-size: 0.95em !important;
    border: 1px solid var(--bs-gray-200) !important;
    padding: 0.55rem 0.75rem 0.55rem 3.35rem !important;
  }

  .ant-input {
    background-color: var(--bs-gray-100) !important;
    // font-size: 0.95em !important;
    color: var(--bs-light-inverse) !important;
  }

  .ant-select-selector {
    background-color: var(--bs-gray-100) !important;
    font-size: 0.95em !important;
    border: 1px solid var(--bs-gray-200) !important;
    min-height: 0 !important;
    height: 35px !important;
    width: 100%;
    padding: 0 0.75rem !important;
  }

  .ant-picker-input {
    > input {
      color: var(--bs-light-inverse) !important; //font-weight: bold;
      font-size: 0.95em;
    }
  }

  .form-select {
    border: 1px solid var(--bs-gray-300) !important;
  }

  .ant-select-selection-placeholder {
    font-size: 0.85em !important;
  }
}

#kt_app_toolbar {
  height: fit-content;
  // background-color: white !important;
}

.rdw-editor-main {
  height: 200px !important;
}

.form-create-event {
  .ant-picker,
  .form-control,
  .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    background-color: var(--bs-gray-100) !important;
    font-size: 0.95em;
    border: 1px solid var(--bs-gray-200);
    padding: 0.55rem 0.75rem;
  }
  textarea {
    min-height: calc(1.5em + 1.65rem + 50px);
  }

  .ant-picker,
  .ant-select {
    @media screen and (max-width: 576px) {
      min-width: 100%;
    }
    @media screen and (min-width: 577px) {
      min-width: 200px;
    }
  }
  .ant-select-selector {
    min-width: 100px;
    min-height: 41px;
  }

  .ant-select-selector {
    background-color: var(--bs-gray-100) !important;
    font-size: 0.95em;
    border: 1px solid var(--bs-gray-200) !important;
    padding: 0.25rem 0.55rem;
  }
  .ant-picker-input {
    > input {
      color: var(--bs-gray-700); //font-weight: bold;
      font-size: 0.95em;
    }
  }

  .form-select {
    border: 1px solid var(--bs-gray-300) !important;
  }
  .ant-select-selection-placeholder {
    font-size: 0.85em !important;
  }
  .MuiOutlinedInput-input {
    border: 1px solid var(--bs-gray-200) !important;
    padding: 0.55rem 0.75rem;
    color: var(--bs-gray-700);
    font-size: 0.95em;
  }
}

.permissionList {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #009ef7 !important;
    border-color: white !important;
  }

  .ant-checkbox-inner {
    height: 1.55rem;
    width: 1.55rem;
    border: 0;
    border-radius: 0.45em;
    background-color: var(--bs-gray-200);
  }
}
.event-date-picker {
  .ant-select-selector {
    min-width: 120px;
  }
}
.modal-country{
  height: 60dvh;
  overflow: auto !important;
  max-height: 60dvh !important;
}