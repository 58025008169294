.symbol.symbol-70px .symbol-label {
  width: 70px;
  height: 70px;
  background-color: transparent !important;
}

.customizedForm {
  input,
  .ant-select-selection-item {
    font-size: 13px !important;
    color: #5e6278 !important;
    font-weight: 500 !important;
  }
}
